<template>
  <div class="chuckie-container">
    <div class="header">
      <div class="logo">🔊 🐕</div>
      <h1>Chuckie Talkie</h1>
      <p class="subtitle"><span class="dog-emoji"></span> Voice Commands for Benjie</p>
    </div>

    <div class="search-bar">
      <input
        v-model="searchQuery"
        type="text"
        placeholder="Search commands..."
        class="search-input"
      />
    </div>

    <div class="commands-grid">
      <button
        v-for="command in filteredCommands"
        :key="command.name"
        class="command-button"
        :class="{ 'is-playing': currentlyPlaying === command.name }"
        :style="{
          '--command-color': command.color
        }"
        @click="playAudio(command)"
      >
        <div class="command-content">
          <div class="command-name-container">
            <div class="command-name">{{ command.name }}</div>
            <div class="command-icon">{{ command.icon }}</div>
          </div>
          <div class="command-description">{{ command.description }}</div>
        </div>
        <div v-if="currentlyPlaying === command.name" class="sound-wave">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
    </div>

    <!-- Mobile-specific "Now Playing" indicator -->
    <transition name="slide-up">
      <div v-if="currentlyPlaying" class="mobile-playing-indicator">
        <div class="playing-content">
          <div class="playing-icon">🔊</div>
          <div class="playing-text">
            <div class="playing-label">Now Playing</div>
            <div class="playing-command">{{ currentlyPlaying }}</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ChuckieTalkie',
  props: {
    commands: {
      type: Array,
      default: () => [
        // MISSING COMMANDS:
        // FOCUS
        {
          name: 'Sit',
          description: 'Sits in place',
          audioFile: 'Sit.mp3',
          icon: '🪑',
          color: '#4CAF50'
        },
        {
          name: 'Down',
          description: 'Lays down',
          audioFile: 'Down.mp3',
          icon: '⬇️',
          color: '#4CAF50'
        },
        {
          name: 'Good Boy',
          description: 'Positive reinforcement',
          audioFile: 'Good.mp3',
          icon: '⭐',
          color: '#FFC107'
        },
        {
          name: 'No',
          description: 'Stop current action',
          audioFile: 'No.mp3',
          icon: '🚫',
          color: '#F44336'
        },
        {
          name: 'Okay',
          description: 'Release from threshold',
          audioFile: 'Okay.mp3',
          icon: '👍',
          color: '#2196F3'
        },
        {
          name: 'Free',
          description: 'Release from command',
          audioFile: 'Free.mp3',
          icon: '🆓',
          color: '#2196F3'
        },
        {
          name: "Let's Go",
          description: 'Continue walking',
          audioFile: 'Go.mp3',
          icon: '🚶',
          color: '#9C27B0'
        },
        {
          name: 'Leave It',
          description: 'Do not touch/drop item',
          audioFile: 'Leave.mp3',
          icon: '🚫',
          color: '#F44336'
        },
        {
          name: 'Out',
          description: 'Release toy',
          audioFile: 'Out.mp3',
          icon: '↪️',
          color: '#2196F3'
        },
        {
          name: 'Go Bathroom',
          description: 'Time to relieve',
          audioFile: 'Bathroom.mp3',
          icon: '🚽',
          color: '#795548'
        },
        {
          name: 'Drink',
          description: 'Drink water',
          audioFile: 'Drink.mp3',
          icon: '💧',
          color: '#795548'
        },
        {
          name: 'Place',
          description: 'Go to designated spot',
          audioFile: 'Place.mp3',
          icon: '🎯',
          color: '#9C27B0'
        },
        {
          name: 'Heel',
          description: 'Walk on left side',
          audioFile: 'Heel.mp3',
          icon: '↩️',
          color: '#9C27B0'
        }
      ]
    }
  },
  data() {
    return {
      currentlyPlaying: null,
      searchQuery: '',
      audioBaseUrl: 'https://chuckie-talkie.s3.us-west-1.amazonaws.com/',
      audioCache: new Map(),
      isLoading: false,
      hasInteracted: false
    };
  },
  computed: {
    filteredCommands() {
      if (!this.searchQuery) return this.commands;
      const query = this.searchQuery.toLowerCase();
      return this.commands.filter(
        command =>
          command.name.toLowerCase().includes(query) ||
          command.description.toLowerCase().includes(query)
      );
    }
  },
  mounted() {
    // Pre-warm audio context
    const warmup = async () => {
      const temp = new (window.AudioContext || window.webkitAudioContext)();
      await temp.resume();
      temp.close();
    };
    warmup();

    // Setup interaction detection
    const setInteracted = () => {
      this.hasInteracted = true;
      document.removeEventListener('touchstart', setInteracted);
      document.removeEventListener('touchend', setInteracted);
      document.removeEventListener('click', setInteracted);
    };

    document.addEventListener('touchstart', setInteracted);
    document.addEventListener('touchend', setInteracted);
    document.addEventListener('click', setInteracted);
  },
  beforeDestroy() {
    // Cleanup
    if (this.currentSource) {
      this.currentSource.stop();
    }
    if (this.audioContext) {
      this.audioContext.close();
    }
  },
  methods: {
    async playAudio(command) {
      if (this.isLoading || this.currentlyPlaying) return;

      try {
        this.isLoading = true;

        // Create a new audio context for EACH play attempt
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        await audioContext.resume();

        // Force unlock audio on iOS
        if (audioContext.state === 'suspended') {
          const unlockAudio = async () => {
            await audioContext.resume();
            document.body.removeEventListener('touchstart', unlockAudio);
            document.body.removeEventListener('touchend', unlockAudio);
            document.body.removeEventListener('click', unlockAudio);
          };
          document.body.addEventListener('touchstart', unlockAudio);
          document.body.addEventListener('touchend', unlockAudio);
          document.body.addEventListener('click', unlockAudio);
        }

        // Fetch audio with explicit headers
        const response = await fetch(`${this.audioBaseUrl}${command.audioFile}`, {
          headers: {
            Range: 'bytes=0-',
            Accept: 'audio/mp3,audio/*;q=0.9,*/*;q=0.8',
            'Cache-Control': 'no-cache'
          }
        });

        // Force full download
        const arrayBuffer = await response.arrayBuffer();

        // Create buffer source
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        const source = audioContext.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(audioContext.destination);

        // Play
        this.currentlyPlaying = command.name;
        source.start(0);

        // Handle completion
        source.onended = () => {
          this.currentlyPlaying = null;
          this.isLoading = false;
          audioContext.close();
        };
      } catch (error) {
        console.error(`Audio error for ${command.name}:`, error);
        this.currentlyPlaying = null;
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.chuckie-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background: #f8f9fa;
  min-height: 100vh;
  position: relative;
}

.header {
  text-align: center;
  margin-bottom: 2rem;
  padding: 2rem;
  background: linear-gradient(135deg, #4caf50, #2196f3);
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  color: white;
}

.logo {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  animation: bounce 2s infinite;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.header h1 {
  font-size: clamp(2rem, 5vw, 3rem);
  margin: 0;
  font-weight: 800;
  background: linear-gradient(45deg, #ffffff, #e3f2fd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.subtitle {
  font-size: clamp(1rem, 3vw, 1.2rem);
  color: rgba(255, 255, 255, 0.9);
  margin-top: 0.5rem;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.dog-emoji {
  display: inline-block;
  animation: wiggle 2s infinite;
}

.search-bar {
  margin-bottom: 2rem;
  padding: 0 1rem;
}

.search-input {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: block;
  padding: 1rem 1.5rem;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: white;
}

.search-input:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
}

.commands-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(280px, 100%), 1fr));
  gap: 1.5rem;
  padding: 1rem;
}

.command-button {
  position: relative;
  background: white;
  border: none;
  border-radius: 16px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  border-left: 4px solid var(--command-color);
}

.command-button:hover {
  transform: translateY(-4px) scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  background: linear-gradient(135deg, color-mix(in srgb, var(--command-color) 5%, white), white);
}

.command-button:active {
  transform: translateY(2px);
}

.command-button.is-playing {
  background: linear-gradient(135deg, color-mix(in srgb, var(--command-color) 15%, white), white);
  box-shadow: 0 0 0 2px var(--command-color);
  animation: pulse 2s infinite;
}

.command-content {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.command-name-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.command-name {
  font-size: clamp(1.2rem, 4vw, 1.4rem);
  font-weight: 700;
  color: #2c3e50;
  line-height: 1.2;
  margin: 0;
}

.command-description {
  font-size: clamp(0.8rem, 3vw, 0.9rem);
  color: #6c757d;
  line-height: 1.3;
  margin: 0;
}

.sound-wave {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  margin-top: 10px;
}

.sound-wave span {
  display: inline-block;
  width: 3px;
  height: 15px;
  background-color: #2196f3;
  animation: wave 1s infinite ease-in-out;
}

.mobile-playing-indicator {
  display: none;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes wiggle {
  0%,
  100% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(10deg);
  }
}

@keyframes wave {
  0%,
  100% {
    transform: scaleY(0.5);
  }
  50% {
    transform: scaleY(1.5);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 color-mix(in srgb, var(--command-color) 40%, transparent);
  }
  70% {
    box-shadow: 0 0 0 10px color-mix(in srgb, var(--command-color) 0%, transparent);
  }
  100% {
    box-shadow: 0 0 0 0 color-mix(in srgb, var(--command-color) 0%, transparent);
  }
}

/* Slide up animation */
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease-out;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .chuckie-container {
    padding: 1rem;
  }

  .header {
    padding: 1.5rem 1rem;
    margin-bottom: 1.5rem;
    border-radius: 16px;
  }

  .commands-grid {
    gap: 1rem;
    padding: 0.5rem;
  }

  .command-button {
    height: 120px;
    padding: 1rem;
  }

  .mobile-playing-indicator {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(33, 150, 243, 0.95);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    padding: 1rem;
    z-index: 1000;
  }

  .playing-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: white;
  }

  .playing-icon {
    font-size: 1.5rem;
    animation: bounce 1s infinite;
  }

  .playing-text {
    text-align: left;
  }

  .playing-label {
    font-size: 0.8rem;
    opacity: 0.8;
  }

  .playing-command {
    font-weight: 600;
    font-size: 1.1rem;
  }

  @supports (padding: max(0px)) {
    .mobile-playing-indicator {
      padding-bottom: max(1rem, env(safe-area-inset-bottom));
    }
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .chuckie-container {
    background: #1a1a1a;
  }

  .header,
  .command-button,
  .search-input {
    background: #2d2d2d;
    color: #ffffff;
  }

  .header h1 {
    background: linear-gradient(45deg, #60a5fa, #3b82f6);
    -webkit-background-clip: text;
  }

  .subtitle,
  .command-description {
    color: #9ca3af;
  }

  .command-name {
    color: #ffffff;
  }

  .search-input {
    border-color: #374151;
    color: #ffffff;
  }

  .search-input::placeholder {
    color: #6b7280;
  }

  .command-button:hover {
    background: linear-gradient(
      135deg,
      color-mix(in srgb, var(--command-color) 15%, #2d2d2d),
      #2d2d2d
    );
  }

  .command-button.is-playing {
    background: linear-gradient(
      135deg,
      color-mix(in srgb, var(--command-color) 30%, #2d2d2d),
      #2d2d2d
    );
    box-shadow: 0 0 0 2px var(--command-color);
  }

  .mobile-playing-indicator {
    background: rgba(29, 78, 216, 0.95);
  }

  .command-icon {
    filter: brightness(1.2);
  }
}

.command-icon {
  font-size: 1.4rem;
  margin-bottom: 0;
}
</style>
